<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
      v-if='login'
    >
     <v-icon >
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />
          <v-switch
            v-model="$vuetify.theme.dark"
            class="ma-0 pa-0"
            color="secondary"
            hide-details
            label="Theme mode"
          />
  

    <v-btn
      class="ml-2"
      min-width="0"
      text
      @click="logout"
      label="Logout"
      v-if="login"
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'

  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
    
    }),

    computed: {
      ...mapState(['drawer','login']),
      
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),

      async logout(){
        await this.$store.dispatch('logoutUser')
        this.$toast.success("Logout Successfully");
        this.$router.push({path:'/login'})
      }
    },
  }
</script>
